import $ from "jquery";
import socket from "./socket";
import styles from "./bot.module.css";

import notificationSound from "./aud-energy-chime-high-note-pure-39249.mp3";
var element = $(".floating-chat");
var myStorage = localStorage;
var loginButton = $("#login_button");
var logoutButton = $("#logout_button");
var userLoggedIn;
var currentUser;
let agentConnectionTimeout;
var messageCount = 0;
const receivedMessageIds = new Set();

// var endpoint;

// fetch('../../../config.json')
//   .then(response => response.json())
//   .then(config => {
//     endpoint = config.url;
//   });

// const currentURL = window.location.href;
const currentURL = `${process.env.REACT_APP_BACKEND_URL}/`;

const endpointToRemove = "chatbot";

const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;

// Remove the endpoint from the URL
const newURL = currentURL.replace(endpointToRemove, "");

// console.log("url");
// console.log(newURL);
// console.log("url");

export const connectToSocketAndStartSession = async (chatIdParam) => {
  try {
    if (!socket.connected) {
      socket.auth = { username: chatIdParam };

      // add parameter type customer or agent

      socket.connect();

      // Add event listeners after connection
      socket.on("connect", () => {
        console.log("Socket connected");
        const currentTime = new Date().getTime();
        const connectionData = {
          isConnected: true,
          timestamp: currentTime,
          chatID: chatIdParam,
        };
        localStorage.setItem(
          "socketConnection",
          JSON.stringify(connectionData)
        );
        // Listen for a specific event
        socket.on("AGENT_TAKE_OVER", (data) => {
          console.log("agent has taken over");
          connectToAgentFlag();
          console.log("Received event:", data);
          socket.on("new-message", (data) => {
            handleNewMessage(data);
          });
          var messagesContainer = document.getElementById("messages");

          var liElement = document.createElement("li");
          liElement.classList.add(styles.self);
          liElement.innerHTML = `You are now talking to ${data.agentName}. How may I assist you today?`;

          messagesContainer.appendChild(liElement);
          // Handle the event data here
        });
      });
    }
  } catch (err) {
    console.log(err);
  }
};
var chatID;

var newChat = true;

if (!myStorage.getItem("chatID")) {
  const newChatId = createUUID();
  console.lo;
  myStorage.setItem("chatID", newChatId);

  chatID = newChatId;
} else {
  newChat = false;
  chatID = myStorage.getItem("chatID");
  connectToSocketAndStartSession(chatID);
}
/* // Check if the lastAccessTime key exists in the storage
  if (myStorage.getItem("lastAccessTime")) {
    // Get the stored date and time
    const storedDateTime = parseInt(myStorage.getItem("lastAccessTime"));

    // Get the current date and time
    const currentDateTime = new Date().getTime();

    // Calculate the time difference in days
    const fifteenMinutes = 60 * 60 * 1000; // 15 minutes in milliseconds
    const timeDiff = currentDateTime - storedDateTime;
    const minutesElapsed = Math.floor(timeDiff / fifteenMinutes);

    // If more than a day has elapsed, clear the storage and generate a new chatID
    if (minutesElapsed > 0) {
      myStorage.clear();
      chatID = createUUID();
      myStorage.setItem("chatID", chatID);
      myStorage.setItem("lastAccessTime", currentDateTime);
    } else {
      chatID = myStorage.getItem("chatID");
    }
  } else {
    // If the lastAccessTime key doesn't exist, store the current date and time
    const currentDateTime = new Date().getTime();

    myStorage.setItem("lastAccessTime", currentDateTime);
  } */

element.click(openElement);

setTimeout(function () {
  element.addClass("enter");
}, 1000);

// api to fetch IP address

// Using ipify API to get the user's IP address
let client_id;

const getIPAddress = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    const ipAddress = data.ip;
    // console.log('User IP address:', ipAddress);
    client_id = ipAddress;

    return ipAddress;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    throw error; // Propagate the error if needed
  }
};
getIPAddress();

const createNewSession = async (ipAddress) => {
  console.log("chat id is ", chatID);
  let ip = ipAddress;
  if (!ip) {
    ip = getIPAddress();
  }
  try {
    const requestBody = {
      ipAddress: ip,
      userAgent: window.navigator.userAgent,
      sessionId: chatID,
    };
    fetch(`${backendUrl}/userSession/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        connectToSocketAndStartSession(chatID);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {
    console.error("an error occured session");
  }
};

// Add this function to your existing JavaScript code
export function iframeLoaded() {
  // Assuming 'element' is the reference to your chat box element
  var chatBox = element.find(".chat");
  // console.log("hello");

  // Check if the chat box has the 'enter' class, indicating it's open
  if (chatBox.hasClass("enter")) {
    // If the chat box is open, set the z-index to a higher value
    // console.log("enter");
    document.getElementById("iframe-giftcartbot").style.zIndex = 9999;
  } else {
    // If the chat box is closed, set the z-index to 0
    // console.log("not enter");
    document.getElementById("iframe-giftcartbot").style.zIndex = 0;
  }
}

export function openElement() {
  var messages = element.find(".messages");
  var textInput = element.find(".text-box");
  element.find(">i").hide();
  element.addClass("expand");
  element.find(".chat").addClass("enter");

  window.parent.postMessage("openChatBox", "*");

  var strLength = textInput.val().length * 2;
  textInput.keydown(onMetaAndEnter).prop("disabled", false).focus();
  element.off("click", openElement);
  element.find(".header button").click(closeElement);
  element.find("#sendMessage").click(sendNewMessage);
  messages.scrollTop(messages.prop("scrollHeight"));
}

export function closeElement() {
  element.find(".chat").removeClass("enter").hide();

  element.find(">i").show();
  element.removeClass("expand");
  element.find(".header button").off("click", closeElement);
  element.find("#sendMessage").off("click", sendNewMessage);
  element
    .find(".text-box")
    .off("keydown", onMetaAndEnter)
    .prop("disabled", true)
    .blur();

  window.parent.postMessage("closeChatBox", "*");

  setTimeout(function () {
    element.find(".chat").removeClass("enter").show();
    element.click(openElement);
  }, 500);
}

export function createUUID() {
  // http://www.ietf.org/rfc/rfc4122.txt
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}

//speech reco
try {
  var SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  var recognition = new SpeechRecognition();
} catch (e) {
  console.error(e);
  $(".no-browser-support").show();
}

$("#start-record-btn").on("click", function (e) {
  recognition.start();
});

recognition.onresult = (event) => {
  const speechToText = event.results[0][0].transcript;
  document.getElementById("text-box-msg").innerHTML = speechToText;
  sendNewMessage();
};

export function openLinkInNewTab(url) {
  const newTab = window.open(url, "_blank");
  newTab.focus(); // Optional: Give focus to the new tab
}
export function transitionsChat(mode) {
  connectToAgentFlag();

  // remove shiftConversationLLi
  var shiftConversationLLi = document.getElementById("shiftConversationLLi");
  if (shiftConversationLLi) {
    shiftConversationLLi.remove();
  }

  if (mode == "whatsapp") {
    // set localstorage talkmode to whatsapp
    localStorage.setItem("talkmode", "whatsapp");
    // make a fetch request to update talkmode in database
    fetch(backendUrl + "/customer/updateTalkMode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        talkMode: "whatsapp",
      }),
    })
      .then((response) => response)
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    var messagesContainer = document.getElementById("messages");
    var liElement = document.createElement("li");
    liElement.classList.add(styles.self);
    liElement.innerHTML =
      "Connected! You're now chatting with a human agent on WhatsApp. Any questions? We're here to help.";
    messagesContainer.appendChild(liElement);

    // hide div with id text-box-msg
    var textBoxElement = document.getElementById("text-box-msg");
    textBoxElement.style.display = "none";

    // hide div with id send-message
    var sendMessageElement = document.getElementById("sendMessage");
    sendMessageElement.style.display = "none";

    // div with id    id="continueChatWithGiftcartBot" which is display none
    var continueChatWithGiftcartBot = document.getElementById(
      "continueChatWithGiftcartBot"
    );
    continueChatWithGiftcartBot.style.display = "block";
    continueChatWithGiftcartBot.addEventListener("click", function () {
      // change flag true
      localStorage.setItem(
        "connectToAgentFlag",
        JSON.stringify({
          value: false,
        })
      );

      const messagesContainer = document.getElementById("messages");

      var liElement = document.createElement("li");
      liElement.classList.add(styles.self);
      liElement.innerHTML = `Continue chatting with Giftcart Bot`;

      messagesContainer.appendChild(liElement);
      updateScrollbar();

      continueChatWithGiftcartBot.remove();

      //add text-box-msg
      textBoxElement.style.display = "block";
      //add send-message
      sendMessageElement.style.display = "block";
      // remove talkmode from localstorage
      localStorage.removeItem("talkmode");
    });

    updateScrollbar();
  } else {
    // set localstorage talkmode to chat
    localStorage.setItem("talkMode", "chat");
    // make a fetch request to update talkmode in database
    fetch(backendUrl + "/customer/updateTalkMode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        talkMode: "chat",
        chat_session_id: chatID,
      }),
    })
      .then((response) => response.text())
      .then((data) => {
        console.log("Successss:", data);
        if (data == "Connecting to live agent") {
          // check if socket is connected if not connect

          var typingText = "Connecting to live agent...";
          var typingSpeed = 100; // Adjust typing speed in milliseconds

          var messagesContainer = document.getElementById("messages");
          var liElement = document.createElement("li");
          liElement.id = "connectingToAgent";
          liElement.classList.add(styles.self);

          liElement.innerHTML = ""; // Empty initial content
          messagesContainer.appendChild(liElement);

          // Function to simulate typing animation
          function typeWriter(text, index) {
            if (index < text.length) {
              liElement.innerHTML += text.charAt(index);
              index++;
              setTimeout(function () {
                typeWriter(text, index);
              }, typingSpeed);
            }
          }

          // Start typing animation
          typeWriter(typingText, 0);

          if (!socket.connected) {
            socket.auth = { username: chatID };

            // add parameter type customer or agent

            socket.connect();

            const currentTime = new Date().getTime();
            const connectionData = {
              isConnected: true,
              timestamp: currentTime,
              chatID: chatID,
            };
            localStorage.setItem(
              "socketConnection",
              JSON.stringify(connectionData)
            );
          }

          console.log("socket connected");

          agentConnectionTimeout = setTimeout(() => {
            console.log("No agent connected within 10 minutes");
            disconnectSocketAndRestoreDialogflow();
          }, 60 * 1000); // 10 minutes in milliseconds

          console.log("agent connection timeout ", agentConnectionTimeout);
          socket.on("AGENT_CONNECTED_TO_CHAT", (data) => {
            clearTimeout(agentConnectionTimeout);

            try {
              var ctaList = document.getElementById("connectingToAgent");
              // remove ctaList
              ctaList.remove();
            } catch (err) {
              console.log(err);
            }
            // remove socket
            socket.off("AGENT_CONNECTED_TO_CHAT");
          });
        } else {
          var messagesContainer = document.getElementById("messages");
          var liElement = document.createElement("li");
          liElement.classList.add(styles.self);
          liElement.innerHTML =
            "Apologies, all agents are currently unavailable. Please create a ticket with your contact info and issue description. We'll address it ASAP. Thank you for your understanding.";
          messagesContainer.appendChild(liElement);

          updateScrollbar();

          //after 10 seconds

          setTimeout(function () {
            var messagesContainer = document.getElementById("messages");

            let htmlCode = `<li class="${styles.self}" id="selfLi" style={{ display: 'none' }}>
    <p>Please create a ticket</p><br/>
    <span>
      <form id="ticketForm">
      
        <p>
          <label>
           Subject<span class=${styles.required}></span>:  </br>
            <input type="text" name="subject" class=${styles.roundedInput} required />
          </label>
        </p>
        <p>
          <label>
          Order Number:</br>
            <input type="number" name="orderNumber" class=${styles.roundedInput}  />
          </label>
        </p>
        <p>
        <label>
        Description<span class=${styles.required}></span>:</br>
          <textarea name="description" class=${styles.roundedInput} required 
          minlength="10" maxlength="1000" rows="5" ></textarea>
         
        </label>
      </p>
        <p><br/>
        <button type="submit" class="${styles.li_button}" id="ticketSubmitButton">Submit</button>
        </p>
      </form>
    </span>
  </li>
  `;
            messagesContainer.insertAdjacentHTML("beforeend", htmlCode);
            updateScrollbar();

            document
              .getElementById("ticketForm")
              .addEventListener("submit", function (event) {
                console.log("Form submitted");
                event.preventDefault(); // Prevent default form submission

                const formData = new FormData(this);
                var chat_session_id = chatID;

                formData.append("chat_session_id", chat_session_id);
                // log all form data
                for (var pair of formData.entries()) {
                  console.log(pair[0] + ", " + pair[1]);
                }

                // convert form data to json
                var object = {};
                formData.forEach(function (value, key) {
                  object[key] = value;
                });
                console.log("object", object);
                fetch(backendUrl + "/ticket/from-bot", {
                  method: "POST",
                  body: JSON.stringify(object),
                  // typee application/json

                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                  .then((response) => response.text())
                  .then(async (data) => {
                    updateScrollbar();
                    // remove ticket form
                    var ticketForm = document.getElementById("ticketForm");

                    ticketForm.remove();
                    var messagesContainer = document.getElementById("messages");

                    var liElement = document.createElement("li");
                    liElement.classList.add(styles.self);
                    liElement.innerHTML = `Thank you for submitting your ticket! Our team will prioritize your inquiry and get back to you soon. For urgent concerns, please contact us directly. We appreciate your patience`;

                    messagesContainer.appendChild(liElement);
                    // add event listener to whatsapp button

                    updateScrollbar();
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                    // Handle errors if any
                  });
              });
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
export function handleSubmit(event) {
  // const nameInput = document.querySelector('input[name="name"]');
  // const emailInput = document.querySelector('input[name="email"]');
  // const nameCustomText = document.querySelector('input[name="name"] + .customText');
  // const emailCustomText = document.querySelector('input[name="email"] + .customText');

  // if (!nameInput.value.trim()) {
  //     nameCustomText.style.display = "inline"; // Show custom text for name input
  // } else {
  //     nameCustomText.style.display = "none"; // Hide custom text for name input
  // }

  // if (!emailInput.value.trim()) {
  //     emailCustomText.style.display = "inline"; // Show custom text for email input
  // } else {
  //     emailCustomText.style.display = "none"; // Hide custom text for email input
  // }

  event.preventDefault(); // Prevent default form submission

  const formData = new FormData(this);
  console.log("Form data:", formData); // Log form data for debugging

  var chat_session_id = chatID;
  console.log("Chat session ID:", chat_session_id); // Log chat session ID for debugging

  const timestamp = new Date().toISOString();
  console.log("Timestamp:", timestamp); // Log timestamp for debugging

  formData.append("chat_session_id", chat_session_id);
  formData.append("timestamp", timestamp);

  console.log("Appended form data:", formData); // Log appended form data for debugging

  // Perform fetch or other actions with formData
}
export function connectToAgentFlag() {
  localStorage.setItem(
    "connectToAgentFlag",
    JSON.stringify({
      value: true,
    })
  );
}
export function toggleStars(whatsapp, chatMedium) {
  console.log("toggleStars");
  // ... your existing code

  if (whatsapp == "TalkToAgent") {
    const messagesContainer = document.getElementById("messages");

    var dynamicForm = document.getElementById("myDynamicForm");

    if (dynamicForm) {
      // If the form exists, remove it
      dynamicForm.remove();
    }

    let htmlCode = `<li class="${styles.self}" id="selfLi" style="width: 75%;">
  <p>Connecting to Agent. Please fill the form!</p>
  <span>
    <form id="myDynamicForm">
      <div class="${styles["form__group"]}">
        <input type="text" name="name" class="${styles["form__field"]}" placeholder="Name" id="name" required autocomplete="name" title="Please enter a valid name (only letters and spaces are allowed)" />
        <label for="name" class="${styles["form__label"]}">Name</label>
      </div>
      <div class="${styles["form__group"]}">
        <input type="email" name="email" class="${styles["form__field"]}" placeholder="Email" id="email" required autocomplete="email" title="Please enter a valid email address" />
        <label for="email" class="${styles["form__label"]}">Email</label>
      </div>
      <div class="${styles["form__group"]}">
        <input type="tel" name="phone" class="${styles["form__field"]}" placeholder="Phone No." maxlength="10" id="phone" required autocomplete="tel" title="Please enter a valid phone number" />
        <label for="phone" class="${styles["form__label"]}">Phone Number</label>
      </div>
      <br/>
      <button type="submit" class="${styles.li_button}" id="submitButton">Submit</button>
    </form>
  </span>
</li>
`;
    messagesContainer.insertAdjacentHTML("beforeend", htmlCode);
    updateScrollbar();

    document
      .getElementById("myDynamicForm")
      .addEventListener("submit", function (event) {
        console.log("Form submitted");
        event.preventDefault(); // Prevent default form submission

        const formData = new FormData(this);
        var chat_session_id = chatID;
        const timestamp = new Date().toISOString();

        formData.append("chat_session_id", chat_session_id);
        formData.append("timestamp", timestamp);

        fetch(backendUrl + "/live-agent/submitForm", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.text())
          .then(async (data) => {
            updateScrollbar();

            transitionsChat(chatMedium);

            $("#selfLi").remove();
            y;
          })
          .catch((error) => {
            console.error("Error:", error);
            // Handle errors if any
          });
      });
  } else {
    console.log("clicked on else");
    var footer = document.getElementById("footer");
    footer.style.display = "none";
  }
  console.log("toggleStars-2");
  setTimeout(updateScrollbar, 100);

  var selfLi = document.getElementById("selfLi");
  selfLi.style.display = "block";

  // var starsDiv = document.getElementById("starsDiv");
  // starsDiv.classList.toggle("hidden");

  // const textBoxElement = document.querySelector(".floating-chat .chat .footer");
  // textBoxElement.style.display = "none";

  // if (whatsapp) {
  //   let linkUrl = "https://wa.me/919910644899";
  //   openLinkInNewTab(linkUrl);
  // }
}

export function toggleStarsTalkTo() {
  let linkUrl = "https://wa.me/919910644899";
  openLinkInNewTab(linkUrl);
}

// pop up message for stars ratings
let animationFrameId; // Store the animation frame ID

export function showThankYouMessage(stars) {
  // console.log("inside showTankyou message");

  // // Get the thank you message div
  // const thankYouMessage = document.getElementById('thankYouMessage');

  // // Clear any previously running animation frames
  // // cancelAnimationFrame(animationFrameId);

  // // Display the thank you message
  // thankYouMessage.style.display = 'block';
  // console.log("stars");
  // console.log(stars);
  // fetchmsg(stars + " stars rating");

  let htmlCode = "Thank you for rating me";

  var messagesContainer = document.getElementById("messages");

  var liElement = document.createElement("li");
  liElement.classList.add(styles.self);
  liElement.textContent = htmlCode;
  messagesContainer.appendChild(liElement);
  setTimeout(updateScrollbar, 100);

  // Trigger a reflow to ensure CSS changes take effect
  // void thankYouMessage.offsetWidth;

  // thankYouMessage.style.opacity = '0'; // Set initial opacity to zero

  // // Delay before starting the animation (adjust as needed)
  // setTimeout(() => {
  //   const startTime = performance.now();

  //   function fadeOut(currentTime) {
  //     const elapsedTime = currentTime - startTime;
  //     const duration = 3000; // 3 seconds

  //     if (elapsedTime < duration) {
  //       // Continue the fade-out animation
  //       const opacity = 1 - (elapsedTime / duration);
  //       thankYouMessage.style.opacity = opacity;
  //       animationFrameId = requestAnimationFrame(fadeOut);
  //     } else {
  //       // Animation complete
  //       thankYouMessage.style.display = 'none';
  //       thankYouMessage.style.opacity = '1'; // Reset opacity for future use
  //     }
  //   }

  //   // Start the fade-out animation
  //   animationFrameId = requestAnimationFrame(fadeOut);
  // }, 50); // Delay in milliseconds before starting the animation
}
fetchmsg;

export function tawkTo() {}

export function sendNewMessage() {
  var userInput = $("#text-box-msg");
  var newMessage = userInput.val();

  if (!newMessage) return;

  if (messageCount === 0) {
    if (newChat) {
      createNewSession(client_id);
    }
  }

  messageCount++;

  // console.log("asd");
  createOtherMessage(newMessage);

  fetchmsg(newMessage);

  // clean out old message
  userInput.val("");
  // focus on input
  userInput.focus();
}

export function createOtherMessage(newMessage) {
  // console.log("inside type message");
  // console.log(newMessage);
  // console.log("inside type message");

  var messagesContainer = document.getElementById("messages");

  var liElement = document.createElement("li");
  liElement.classList.add(styles.other);
  liElement.textContent = newMessage;
  messagesContainer.appendChild(liElement);

  updateScrollbar();

  // messagesContainer.append(
  //   ['<li class="other">', newMessage, "</li>"].join("")
  // );
}

function showThinkingAnimation() {
  // console.log("......");
  var messagesContainer = document.getElementById("messages");
  var thinkingIndicator = document.createElement("li");
  thinkingIndicator.classList.add(styles.self);
  thinkingIndicator.classList.add(styles.thinking_indicator);
  messagesContainer.appendChild(thinkingIndicator);

  updateScrollbar();

  // Hide the thinkingIndicator element initially
  thinkingIndicator.style.display = "none";
  thinkingIndicator.innerHTML = "<i>Typing</i>";
  thinkingIndicator.querySelector("i").style.opacity = "0.7";
  thinkingIndicator.querySelector("i").style.fontSize = "12px";
  thinkingIndicator.style.border = "none";
  thinkingIndicator.style.boxShadow = "none";
  thinkingIndicator.style.display = "block";

  // Start typing animation
  var dotsCount = 1;
  var typingInterval = setInterval(function () {
    if (dotsCount <= 4) {
      thinkingIndicator.querySelector("i").innerText += ".";
      dotsCount++;
    } else {
      thinkingIndicator.querySelector("i").innerText = "Typing";
      dotsCount = 1;
    }
  }, 500); // Change the interval duration as needed

  // Clear the interval after a certain time or event
  return function stopThinkingAnimation() {
    clearInterval(typingInterval);
    thinkingIndicator.remove();
  };
}

// const db = firestore();

export async function checkFlagAndLog() {
  console.log("inside checking flag");
  const connectFlag =
    JSON.parse(localStorage.getItem("connectToAgentFlag")) || {};

  if (connectFlag.value) {
    console.log("inside checking flag true");
    return true;
  }
  return false;
}

export async function LiveAgentMessage(newMessage, source) {
  console.log("LiveAgentMessage function called on connection break");
  var url = backendUrl + "/chatbot/botMessage/" + chatID;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      message: newMessage,
    }),
  });
  try {
    saveMessageToLocal("user", source !== undefined ? source : newMessage);
    saveMessageToDatabase("user", source !== undefined ? source : newMessage);

    // send fetch post to live agent

    const data = await response.json();

    console.log("data from live agent", data);

    var messagesContainer = document.getElementById("messages");
  } catch (error) {
    console.error("Error in LiveAgentMessage:", error.message);
  }
}

// Function to replace class names with styles
function replaceClassesWithStyles(html) {
  if (!/<[a-z][\s\S]*>/i.test(html)) {
    return html; // Return the original text if it's plain without HTML tags
  }
  // Define a regular expression to match class names
  var classRegex = /class="([^"]*)"/g;

  // Replace class names with styles
  return html.replace(classRegex, (match, className) => {
    // Split multiple class names into an array
    var classNames = className.split(" ");

    // Check if 'box-card' class exists, if so, add 'gifts_self' class
    /*  if (classNames.includes("box_card")) {
      classNames.push(styles.gifts_self);
    } */

    // Check if 'self' class exists, if so, replace it with 'gifts_last_self' class
    var updatedClassNames = classNames.map((name) =>
      name === "self" ? styles.gifts_last_self : name
    );

    // Convert each class name to its corresponding style
    var styledClassNames = updatedClassNames.map(
      (name) => styles[name] || name
    );

    // Join the styled class names back into a single string
    return `class="${styledClassNames.join(" ")}"`;
  });
}

async function fetchmsg(newMessage, source) {
  if (typeof window !== "undefined") {
    console.log("inside fetchmsg");
    console.log(newMessage);
    console.log(source);

    const flagValue = await checkFlagAndLog();

    console.log("flagValue: " + flagValue);

    console.log("flag value after breakup : ", flagValue);
    console.log("source value after breakup : ", source);

    console.log("newMessage: " + newMessage);

    if (flagValue == true) {
      await LiveAgentMessage(newMessage, source);
      console.log("connection break detected");
      return;
    }

    if (source) {
      // console.log("inside if condition");
      var messagesContainer = document.getElementById("messages");
      // console.log("messagesContainer",messagesContainer);
      var modifiedSource = source.replace("for General", "").trim();
      var liElement = document.createElement("li");
      liElement.classList.add(styles.other);
      liElement.textContent = modifiedSource;
      messagesContainer.appendChild(liElement);
    }

    var url = newURL + "send-msg";

    const data = new URLSearchParams();

    // console.log(newMessage);

    data.append("MSG", newMessage);

    updateScrollbar();

    const stopThinkingAnimation = showThinkingAnimation();
    updateScrollbar();
    // console.log("abc", data);
    fetch(url, {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((response) => {
        // console.log("Hello: " + response.Reply);
        updateScrollbar();
        stopThinkingAnimation();
        saveMessageToLocal("user", source !== undefined ? source : newMessage);
        saveMessageToDatabase(
          "user",
          source !== undefined ? source : newMessage
        );

        // var messagesContainer = document.getElementById("messages");
        // var liElementBot = document.createElement("li");
        // liElementBot.classList.add(styles.self);
        // liElementBot.textContent = response.Reply;
        // messagesContainer.appendChild(liElementBot);

        console.log(response);

        if (response.Reply == "trigger:talkToAgent") {
          toggleStars("TalkToAgent");
          return;
        }

        // Modify response HTML by replacing '-' with '_'
        // Modify response HTML by replacing '-' with '_' only within class names
        var modifiedHtml = response.Reply.replace(
          /class="([^"]*)"/g,
          function (match, classContent) {
            return 'class="' + classContent.replaceAll("-", "_") + '"';
          }
        );
        // Replace class names and append modified HTML to liElementBot
        var messagesContainer = document.getElementById("messages");
        var liElementBot = document.createElement("li");
        liElementBot.classList.add(styles.self);

        // Apply styles to all class names within the modified HTML
        var styledHtml = replaceClassesWithStyles(modifiedHtml);

        // Set the inner HTML of liElementBot with the styled HTML
        liElementBot.innerHTML = styledHtml;

        // Check if the HTML contains any <li> with class 'self'
        if (modifiedHtml.includes("self")) {
          // Create a new <li> element with class 'gifts_self'
          var newLiElement = document.createElement("li");
          newLiElement.classList.add(styles.gifts_self);
          messagesContainer.appendChild(newLiElement);
        }

        // Append liElementBot to the messagesContainer
        messagesContainer.appendChild(liElementBot);
        playNewMessageSound();
        updateScrollbar();
        saveMessageToLocal("bot", response.Reply);
        saveMessageToDatabase("bot", response.Reply);
      })
      .catch((error) => console.log("Error: ", error.message));
  }
}
// Attach fetchmsg to window object
window.fetchmsg = fetchmsg;
window.dialogflowShowOptions = dialogflowShowOptions;
window.toggleStars = toggleStars;
window.showThankYouMessage = showThankYouMessage;
window.disableStars = disableStars;
window.handleSubmit = handleSubmit;
window.fetchOrderwithEmail = fetchOrderwithEmail;
window.promptForRatings = promptForRatings;
export { fetchmsg };
// Function to save message to database
export function saveMessageToDatabase(sender, message, agentId = null) {
  // console.log("Saving message to database");
  // console.log(sender, message);

  // console.log("current url")
  // console.log(window.location.href)
  // console.log("current url")

  // console.log("client id is");
  // console.log(client_id);
  // console.log("client id is");

  var url = backendUrl + "/save-msg";

  var source = "bot";

  var chat_session_id = chatID;
  // console.log("chat_session_id",chat_session_id);

  const msgToBeSaved = {
    sender,
    message,
    source,

    chat_session_id,
    client_id,
  };
  if (agentId) {
    msgToBeSaved.agentIdIfAgentMsg = agentId;
  }
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...msgToBeSaved,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      // console.log("Message saved successfully:", data);
    })
    .catch((error) => {
      console.error("Error saving message to the database:", error);
    });
}

// Function to save messages to local storage
export function saveMessageToLocal(type, message) {
  const savedChats = JSON.parse(localStorage.getItem("chats")) || [];

  savedChats.push({ type, text: message });
  localStorage.setItem("chats", JSON.stringify(savedChats));
}

// Function to load and display chat history from local storage
export function loadChatHistory() {
  if (typeof window !== "undefined") {
    const savedChats = JSON.parse(localStorage.getItem("chats")) || [];
    var messagesContainer = document.getElementById("messages");

    for (const chat of savedChats) {
      // Modify response HTML by replacing '-' with '_'
      var modifiedHtml = chat.text.replace(
        /class="([^"]*)"/g,
        function (match, classContent) {
          return 'class="' + classContent.replaceAll("-", "_") + '"';
        }
      );
      var styledHtml = replaceClassesWithStyles(modifiedHtml);

      const messageClassValue = chat.type === "user" ? "other" : "self";
      var liElement = document.createElement("li");
      liElement.classList.add(styles[messageClassValue]);
      liElement.innerHTML = styledHtml;

      messagesContainer.appendChild(liElement);
    }

    updateScrollbar();
  }
}

// Function to clear chat history from local storage after a certain time
export function clearLocalStorageAfterTime(minutes) {
  setTimeout(function () {
    localStorage.removeItem("chats");
    // console.log('localStorage chat history cleared.');
  }, minutes * 60 * 1000); // Convert minutes to milliseconds
}

//minutes * 60 * 1000

// Call this function to start the timer (e.g., clear after 24 hours)
clearLocalStorageAfterTime(24 * 60); // Clear after 24 hours

// Listen for the storage event to update chat history across tabs/windows
window.addEventListener("storage", function (event) {
  if (event.key === "chats") {
    loadChatHistory();
  }
});

export function logout() {
  // console.log(window.location.href + "logout");
  $.ajax({
    url: window.location.href + "logout",
    type: "GET",
    success: function (result) {
      // console.log(result);
      loginButton.removeClass("hidden");
      logoutButton.addClass("hidden");
      updateUI();
    },
  });
}

export function onMetaAndEnter(event) {
  if (typeof window !== "undefined") {
    if (event.metaKey || event.ctrlKey || event.keyCode == 13) {
      // if (event.keyCode == 13) {
      event.preventDefault();
      sendNewMessage();
    }
  }
}

function updateScrollbar() {
  var messagesContainer = document.getElementById("messages");
  messagesContainer.scrollTop = messagesContainer.scrollHeight;
}

export function updateUI() {
  if (currentUser) {
    loginButton.addClass("hidden");
    // console.log(JSON.stringify(currentUser));
    if (currentUser.email) {
      $("#welcome").text("Welcome " + currentUser.email);
    } else if (currentUser.phoneNumber) {
      $("#welcome").text("Welcome " + currentUser.phoneNumber);
    }
  } else {
    // console.log("not welcome");
    // $('#welcome').html('User Logged Out. Please Sign in.')
  }
}

export function getCurrentUser() {
  $.ajax({
    url: window.location.href + "get-current-user",
    type: "GET",
  })
    .done((res) => {
      if (res) {
        currentUser = res;
        updateUI();
      } else {
        logoutButton.addClass("hidden");
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function disableStars() {
  // Get all star input elements
  var starInputs = document.querySelectorAll(
    'input[type="radio"][name="rating"]'
  );

  // Loop through the inputs and disable them
  starInputs.forEach(function (input) {
    input.disabled = true;
  });
}

// console.log("script starts");

// Create a Set to store added event names
const addedEvents = new Set();

// ...

// Define a variable to store the columns
let columnsEvents = [];

// console.log("before function");

// console.log("outside function");
var textFieldItemSend = null;

function capitalizeFirstLetter(text) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
window.capitalizeFirstLetter = capitalizeFirstLetter;

export { capitalizeFirstLetter };
export function showOptions() {
  if (typeof window !== "undefined") {
    var optionsDiv = document.getElementById("options");

    if (!textFieldItemSend) {
      // Create a new list item (li) for the text field
      textFieldItemSend = document.createElement("li");
      textFieldItemSend.classList.add(styles.other);
      textFieldItemSend.innerText = "Send a Gift";
    }

    // Set the visibility of the "Track Order" li to "visible" before appending it
    textFieldItemSend.style.visibility = "visible";

    // Insert the "Track Order" list item before the first child of optionsDiv (i.e., above other options)
    optionsDiv.insertBefore(textFieldItemSend, optionsDiv.firstChild);

    var optionsDiv = document.getElementById("options");
    optionsDiv.style.display = "block";
    updateScrollbar();
  }
}
var textFieldMenItem = null;
var lastextFieldMenItem = null;

var textFieldWoMenItem = null;
var lasttextFieldWoMenItem = null;

var textFieldOthersItem = null;
var lasttextFieldOthersItem = null;

export function toggleHusbandBoyfriendOptions(event) {
  // console.log("1.inside toggleHusbandBoyfriendOptions");
  if (typeof window !== "undefined") {
    let div1 = document.getElementById("-options-event-others-placeholder");
    if (div1) {
      div1.style.display = "none";
    }

    let div2 = document.getElementById("-options-event-women-placeholder");
    if (div2) {
      // console.log("inside div2 flow");
      div2.style.display = "none";
    }
    event.preventDefault(); // Prevent the default behavior of the link

    // console.log("inside men events function");

    var button = event.target;
    var value = button.getAttribute("data-value");

    // console.log("value",value);

    var optionsDiv = document.getElementById("-options-event-placeholder");
    // console.log("2.optionsDiv", optionsDiv);

    if (textFieldMenItem) {
      console.log("inside textFieldMenItem");
      optionsDiv.innerHTML = optionsDiv.innerHTML.replaceAll(
        lastextFieldMenItem,
        value
      );

      lastextFieldMenItem = value;

      const liElement = document.getElementById("otherId");

      let finalValue = value.charAt(0).toUpperCase() + value.slice(1);
      // console.log(finalValue);
      if (finalValue == "General") {
        finalValue = "Skip";
      }
      liElement.innerText = finalValue;
    } else {
      // console.log("3.inside else");
      optionsDiv.innerHTML = optionsDiv.innerHTML.replaceAll(
        "(placeholder)",
        value
      );
      console.log("optionsDiv.innerHTML", optionsDiv);
      // console.log("4.lastextFieldMenItem", lastextFieldMenItem);
      // console.log("5.value", value);
      lastextFieldMenItem = value;

      // console.log("6.lastextFieldMenItem", lastextFieldMenItem);
      // console.log("7.value", value);

      textFieldMenItem = document.createElement("li");
      textFieldMenItem.classList.add(styles.other);
      textFieldMenItem.id = "otherId";
      var finalValue = value.charAt(0).toUpperCase() + value.slice(1);

      if (finalValue == "General") {
        finalValue = "Skip";
      }
      textFieldMenItem.innerText = finalValue;

      // Set the visibility of the "Track Order" li to "visible" before appending it
      textFieldMenItem.style.visibility = "visible";

      // Insert the "Track Order" list item before the first child of optionsDiv (i.e., above other options)
      optionsDiv.insertBefore(textFieldMenItem, optionsDiv.firstChild);
    }
    addLastElements("-options-event-placeholder", value);
    const divToMove = document.getElementById("-options-event-placeholder"); // Replace with your div's ID
    const messagesContainer = document.getElementById("messages");

    // Get the last message element within the messages container
    const lastMessage = messagesContainer.lastElementChild;

    // Move the div after the last message
    if (divToMove && lastMessage) {
      lastMessage.insertAdjacentElement("afterend", divToMove);
    }

    // var allGiftsLink = optionsDiv.querySelector("a[href*='All gifts']");
    var birthdayGiftsLink = optionsDiv.querySelector(".birthday-link");
    var anniversaryGiftsLink = optionsDiv.querySelector(".anniversary-link");
    var hatkeLink = optionsDiv.querySelector(".hatke-link");
    var naughtyGiftsLink = optionsDiv.querySelector(".naughty-link");
    var personalisedLink = optionsDiv.querySelector(".personalised-link");
    var loveRomanceLink = optionsDiv.querySelector(".loveandromance-link");
    var loveGiftsLink = optionsDiv.querySelector(".lovegifts-link");
    var weddingGiftsLink = optionsDiv.querySelector(".wedding-link");

    // Hide optionsDiv initially
    optionsDiv.style.display = "none";

    if (value === "husband") {
      // allGiftsLink.style.display = "block";
      // birthdayGiftsLink.style.display = "";
      anniversaryGiftsLink.style.display = "";
      hatkeLink.style.display = "";
      naughtyGiftsLink.style.display = "";
      personalisedLink.style.display = "";
      loveRomanceLink.style.display = "";
      loveGiftsLink.style.display = "none";
      weddingGiftsLink.style.display = "none";
    }

    if (value === "couples") {
      // allGiftsLink.style.display = "";
      birthdayGiftsLink.style.display = "none";
      anniversaryGiftsLink.style.display = "";
      hatkeLink.style.display = "";
      naughtyGiftsLink.style.display = "";
      personalisedLink.style.display = "";
      loveRomanceLink.style.display = "";
      loveGiftsLink.style.display = "none";
      weddingGiftsLink.style.display = "";
    }
    if (value === "boyfriend") {
      var newDiv = document.createElement("div");
      // newDiv.classList.add("column"); // Add any desired class to the new div
      newDiv.innerHTML = `
    <a href="javascript:fetchmsg('I want gift suggestion for Boyfriend in naughty gifts','Naughty Gifts for Boyfriend')" class=${styles.li_button}>Naughty Gifts</a>
    `;

      loveGiftsLink.parentNode.insertBefore(newDiv, loveGiftsLink.nextSibling);

      // allGiftsLink.style.display = "";
      birthdayGiftsLink.style.display = "";
      anniversaryGiftsLink.style.display = "none";
      hatkeLink.style.display = "";
      naughtyGiftsLink.style.display = "none";
      personalisedLink.style.display = "";
      loveRomanceLink.style.display = "";
      loveGiftsLink.style.display = "";
      weddingGiftsLink.style.display = "none";
    }
    if (value === "dad") {
      // allGiftsLink.style.display = "";
      birthdayGiftsLink.style.display = "";
      anniversaryGiftsLink.style.display = "";
      hatkeLink.style.display = "none";
      naughtyGiftsLink.style.display = "none";
      personalisedLink.style.display = "";
      loveRomanceLink.style.display = "none";
      loveGiftsLink.style.display = "none";
      weddingGiftsLink.style.display = "none";
    }
    if (value === "brother") {
      // allGiftsLink.style.display = "";
      birthdayGiftsLink.style.display = "";
      anniversaryGiftsLink.style.display = "none";
      hatkeLink.style.display = "none";
      naughtyGiftsLink.style.display = "none";
      personalisedLink.style.display = "";
      loveRomanceLink.style.display = "none";
      loveGiftsLink.style.display = "none";
      weddingGiftsLink.style.display = "none";
    }
    if (value === "friend") {
      // allGiftsLink.style.display = "";
      birthdayGiftsLink.style.display = "";
      anniversaryGiftsLink.style.display = "none";
      hatkeLink.style.display = "none";
      naughtyGiftsLink.style.display = "none";
      personalisedLink.style.display = "";
      loveRomanceLink.style.display = "none";
      loveGiftsLink.style.display = "none";
      weddingGiftsLink.style.display = "none";
    }
    if (value === "general") {
      // console.log("inside skip flow");
      // allGiftsLink.style.display = "";
      birthdayGiftsLink.style.display = "";
      anniversaryGiftsLink.style.display = "";
      hatkeLink.style.display = "";
      naughtyGiftsLink.style.display = "none";
      personalisedLink.style.display = "";
      loveRomanceLink.style.display = "";
      loveGiftsLink.style.display = "";
      weddingGiftsLink.style.display = "";
    }

    if (optionsDiv.style.display === "none") {
      setTimeout(function () {
        optionsDiv.style.display = "block";
        updateScrollbar();
      }, 400); // Change '2000' to the desired delay time in milliseconds (e.g., 2000 for 2 seconds)
    } else {
      optionsDiv.style.display = "none";
      updateScrollbar();
    }
  }
}

// women collection function
export function toggleWomenOptions(event) {
  if (typeof window !== "undefined") {
    event.preventDefault(); // Prevent the default behavior of the link

    // console.log("inside women events function");

    let div1 = document.getElementById("-options-event-others-placeholder");
    if (div1) {
      div1.style.display = "none";
    }

    let div2 = document.getElementById("-options-event-placeholder");
    if (div2) {
      div2.style.display = "none";
    }

    var button = event.target;
    var value = button.getAttribute("data-value");

    var optionsDiv = document.getElementById(
      "-options-event-women-placeholder"
    );

    if (textFieldWoMenItem) {
      optionsDiv.innerHTML = optionsDiv.innerHTML.replaceAll(
        lasttextFieldWoMenItem,
        value
      );
      lasttextFieldWoMenItem = value;

      const liElement = document.getElementById("otherIdWomen");

      let finalValue = value.charAt(0).toUpperCase() + value.slice(1);

      liElement.innerText = finalValue;
    } else {
      optionsDiv.innerHTML = optionsDiv.innerHTML.replaceAll(
        "(placeholder)",
        value
      );
      lasttextFieldWoMenItem = value;
      // Create a new list item (li) for the text field
      textFieldWoMenItem = document.createElement("li");
      textFieldWoMenItem.className = "other";
      textFieldWoMenItem.id = "otherIdWomen";
      let finalValue = value.charAt(0).toUpperCase() + value.slice(1);
      textFieldWoMenItem.innerText = finalValue;

      // Set the visibility of the "Track Order" li to "visible" before appending it
      textFieldWoMenItem.style.visibility = "hidden";

      // Insert the "Track Order" list item before the first child of optionsDiv (i.e., above other options)
      optionsDiv.insertBefore(textFieldWoMenItem, optionsDiv.firstChild);
    }

    addLastElements("-options-event-women-placeholder", value);
    // console.log("Value inside women flow");

    const divToMove = document.getElementById(
      "-options-event-women-placeholder"
    ); // Replace with your div's ID
    const messagesContainer = document.getElementById("messages");

    // Get the last message element within the messages container
    const lastMessage = messagesContainer.lastElementChild;

    // Move the div after the last message
    if (divToMove && lastMessage) {
      lastMessage.insertAdjacentElement("afterend", divToMove);
    }

    var birthdayGiftsLink = optionsDiv.querySelector(".birthday-link");
    var anniversaryGiftsLink = optionsDiv.querySelector(".anniversary-link");
    var hatkeLink = optionsDiv.querySelector(".hatke-link");
    var jewelleryGiftsLink = optionsDiv.querySelector(".jewellery-link");
    var personalisedLink = optionsDiv.querySelector(".personalised-link");
    var loveRomanceLink = optionsDiv.querySelector(".loveandromance-link");
    var loveGiftsLink = optionsDiv.querySelector(".lovegifts-link");
    var perfumesGiftsLink = optionsDiv.querySelector(".perfumes-link");

    var GiftBasketsLink = optionsDiv.querySelector(".giftbasketshampers-link");
    // Hide optionsDiv initially
    optionsDiv.style.display = "none";

    if (value === "wife") {
      birthdayGiftsLink.style.display = "";
      anniversaryGiftsLink.style.display = "";
      hatkeLink.style.display = "";
      jewelleryGiftsLink.style.display = "";
      personalisedLink.style.display = "";
      loveRomanceLink.style.display = "";
      loveGiftsLink.style.display = "none";
      perfumesGiftsLink.style.display = "";
      GiftBasketsLink.style.display = "none";
    }

    if (value === "girlfriend") {
      birthdayGiftsLink.style.display = "";
      anniversaryGiftsLink.style.display = "none";
      hatkeLink.style.display = "";
      jewelleryGiftsLink.style.display = "";
      personalisedLink.style.display = "";
      loveRomanceLink.style.display = "";
      loveGiftsLink.style.display = "";
      perfumesGiftsLink.style.display = "";
      GiftBasketsLink.style.display = "none";
    }

    if (value === "mother") {
      birthdayGiftsLink.style.display = "";
      anniversaryGiftsLink.style.display = "";
      hatkeLink.style.display = "none";
      jewelleryGiftsLink.style.display = "";
      personalisedLink.style.display = "";
      loveRomanceLink.style.display = "none";
      loveGiftsLink.style.display = "none";
      perfumesGiftsLink.style.display = "none";
      GiftBasketsLink.style.display = "";
    }

    if (value === "sister") {
      birthdayGiftsLink.style.display = "";
      anniversaryGiftsLink.style.display = "none";
      hatkeLink.style.display = "none";
      jewelleryGiftsLink.style.display = "";
      personalisedLink.style.display = "";
      loveRomanceLink.style.display = "none";
      loveGiftsLink.style.display = "none";
      perfumesGiftsLink.style.display = "";
      GiftBasketsLink.style.display = "";
    }

    if (optionsDiv.style.display === "none") {
      setTimeout(function () {
        optionsDiv.style.display = "block";
        updateScrollbar();
      }, 400); // Change '2000' to the desired delay time in milliseconds (e.g., 2000 for 2 seconds)
    } else {
      optionsDiv.style.display = "none";
    }
    updateScrollbar();
  }
}

export function dialogflowShowOptions() {
  showOptions();
  updateScrollbar();

  const divToMove = document.getElementById("options"); // Replace with your div's ID
  const messagesContainer = document.getElementById("messages");

  // Get the last message element within the messages container
  const lastMessage = messagesContainer.lastElementChild;

  // Move the div after the last message
  if (divToMove && lastMessage) {
    lastMessage.insertAdjacentElement("afterend", divToMove);
  }
}

export function fetchOrderwithEmail(email, orderId) {
  // console.log("inside fetch order");
  updateScrollbar();
  let message = `Track order ${orderId} with email ${email}`;
  fetchmsg(message);
}

export function promptForRatings() {
  console.log("inside ratings flow");

  const messagesContainer = document.getElementById("messages");

  let htmlCode = `
    <li class=${styles.self} id="selfLi" style="display: none;">Please rate me
      <div id="starsDiv" class=${styles.rating}>
        <input type="radio" id="star5" name="rating" value="5" onclick="showThankYouMessage(5); disableStars();" /><label for="star5" class="${styles.star_label} ${styles.star5}"></label> <!-- Happy emoji -->
        <input type="radio" id="star4" name="rating" value="4" onclick="showThankYouMessage(4); disableStars();" /><label for="star4" class="${styles.star_label} ${styles.star4}"></label> <!-- Slightly happy emoji -->
        <input type="radio" id="star3" name="rating" value="3" onclick="showThankYouMessage(3); disableStars();" /><label for="star3" class="${styles.star_label} ${styles.star3}"></label> <!-- Neutral emoji -->
        <input type="radio" id="star2" name="rating" value="2" onclick="showThankYouMessage(2); disableStars();" /><label for="star2" class="${styles.star_label} ${styles.star2}"></label> <!-- Slightly sad emoji -->
        <input type="radio" id="star1" name="rating" value="1" onclick="showThankYouMessage(1); disableStars();" /><label for="star1" class="${styles.star_label} ${styles.star1}"></label> <!-- Sad emoji -->
      </div>
    </li>
  `;

  // Append the HTML code to the messages container
  messagesContainer.insertAdjacentHTML("beforeend", htmlCode);
  // Call the function to update the scrollbar
  setTimeout(updateScrollbar, 100);
}

// women collection function
export function toggleOthersOptions(event) {
  if (typeof window !== "undefined") {
    event.preventDefault(); // Prevent the default behavior of the link

    // console.log("inside others events function");

    let div1 = document.getElementById("-options-event-placeholder");
    if (div1) {
      div1.style.display = "none";
    }

    let div2 = document.getElementById("-options-event-women-placeholder");
    if (div2) {
      div2.style.display = "none";
    }
    const divToMove = document.getElementById(
      "-options-event-others-placeholder"
    ); // Replace with your div's ID
    // console.log(divToMove);
    const messagesContainer = document.getElementById("messages");
    console.log(messagesContainer);

    // Get the last message element within the messages container
    const lastMessage = messagesContainer.lastElementChild;

    // Move the div after the last message
    if (divToMove && lastMessage) {
      lastMessage.insertAdjacentElement("afterend", divToMove);
    }

    var button = event.target;
    var value = button.getAttribute("data-value");

    var optionsDiv = document.getElementById(
      "-options-event-others-placeholder"
    );

    if (textFieldOthersItem) {
      // console.log("inside update");

      optionsDiv.innerHTML = optionsDiv.innerHTML.replaceAll(
        lasttextFieldOthersItem,
        value
      );

      lasttextFieldOthersItem = value;

      const liElement = document.getElementById("othersIdcategory");

      let finalValue = value.charAt(0).toUpperCase() + value.slice(1);

      liElement.innerText = finalValue;
    } else {
      optionsDiv.innerHTML = optionsDiv.innerHTML.replaceAll(
        "(placeholder)",
        value
      );

      lasttextFieldOthersItem = value;

      // Create a new list item (li) for the text field
      textFieldOthersItem = document.createElement("li");
      textFieldOthersItem.className = "other";
      textFieldOthersItem.id = "othersIdcategory";
      var finalValue = value.charAt(0).toUpperCase() + value.slice(1);
      textFieldOthersItem.innerText = finalValue;

      // Set the visibility of the "Track Order" li to "visible" before appending it
      textFieldOthersItem.style.visibility = "visible";

      // Insert the "Track Order" list item before the first child of optionsDiv (i.e., above other options)
      optionsDiv.insertBefore(textFieldOthersItem, optionsDiv.firstChild);
    }

    addLastElements("-options-event-others-placeholder", value);

    // Hide optionsDiv initially
    optionsDiv.style.display = "none";

    if (optionsDiv.style.display === "none") {
      setTimeout(function () {
        optionsDiv.style.display = "block";
        updateScrollbar();
      }, 600); // Change '2000' to the desired delay time in milliseconds (e.g., 2000 for 2 seconds)
    } else {
      optionsDiv.style.display = "none";
    }
    updateScrollbar();
  }
}

function addLastElements(category, placeholder) {
  // console.log("before events");

  // console.log("1st");

  populateFestivalsList(newURL + "festivals", category, placeholder);

  // Wait for a short duration before adding the last elements
  //   setTimeout(() => {
  //     console.log("Other added");
  //     // console.log("after events");

  //     var optionsDivSkip = document.getElementById(category);
  //     // Assuming you have defined the columns array

  //     var removeElement1 = optionsDivSkip.querySelector(`a[href*='Skip']`);
  //     var removeElement2 = optionsDivSkip.querySelector(`a[href*='Others']`);
  //     if (removeElement1 && removeElement2) {
  //       // console.log("sucess flow");
  //       removeElement1.remove();
  //       removeElement2.remove();
  //     }

  //     const columns = optionsDivSkip.querySelectorAll(".column");

  //     // Create div elements for the static content
  //     const staticDiv1 = document.createElement("div");
  //     staticDiv1.innerHTML = `
  //     <a href="javascript:fetchmsg('I want gift suggestion for ${placeholder}','Others')" class=${styles.li_button}>Others</a>
  // `;

  //     const staticDiv2 = document.createElement("div");
  //     staticDiv2.innerHTML = `
  //     <a href="javascript:fetchmsg('I want gift suggestion for ${placeholder} in all gifts','Skip')" class=${styles.li_button}>Skip</a>
  // `;

  //     // Append the static divs to the appropriate columns
  //     columns[0].appendChild(staticDiv1);
  //     columns[1].appendChild(staticDiv2);
  //   }, 0); // Adjust the delay as needed
}

// festival logic
function populateFestivalsList(jsonFilename, category, placeholder) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  document.getElementById("gift-recommendations-column").innerHTML = "";
  document.getElementById("gift-recommendations-column-2").innerHTML = "";

  console.log(
    "loggedDocument",
    document.getElementById("gift-recommendations-column")
  );
  const numEventsToFetch =
    {
      husband: 7,
      boyfriend: 7,
      couples: 7,
      wife: 8,
      girlfriend: 8,
      general: 8,
      mother: 6,
      sister: 6,
      brother: 3,
      friend: 3,
      kids: 5,
      babies: 5,
    }[placeholder] || 4;

  const xhr = new XMLHttpRequest();
  xhr.open("GET", jsonFilename, true);

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      const festivals = JSON.parse(xhr.responseText);
      const optionsDiv1 = document.getElementById(category);
      let counter = 0;
      let specificEventsFound = 0;
      addedEvents.clear();

      // Remove previously added events
      addedEvents.forEach((event) => {
        const escapedEvent = event.replace("'", "\\'");
        const removeElement = optionsDiv1.querySelector(
          `a[href*="${escapedEvent}"]`
        );
        if (removeElement) {
          removeElement.remove();
        }
      });

      addedEvents.clear();

      // Sort festivals by date
      festivals.sort((a, b) => new Date(a.date) - new Date(b.date));

      // First pass: Add specific events
      festivals.forEach((event) => {
        if (counter >= numEventsToFetch) return;

        const eventDate = new Date(event.date);
        const eventYear = eventDate.getFullYear();
        const eventMonth = eventDate.getMonth() + 1;
        const eventDay = eventDate.getDate();

        if (
          event.relevantFor.includes(placeholder) &&
          (eventYear > currentYear ||
            (eventYear === currentYear &&
              (eventMonth > currentMonth ||
                (eventMonth === currentMonth && eventDay >= currentDay))))
        ) {
          addEventToList(event, placeholder, optionsDiv1);
          specificEventsFound++;
          counter++;
        }
      });

      // Second pass: Add general events if needed
      if (specificEventsFound === 0) {
        festivals.forEach((event) => {
          if (counter >= numEventsToFetch) return;

          const eventDate = new Date(event.date);
          const eventYear = eventDate.getFullYear();
          const eventMonth = eventDate.getMonth() + 1;
          const eventDay = eventDate.getDate();

          if (
            event.relevantFor.includes("general") &&
            (eventYear > currentYear ||
              (eventYear === currentYear &&
                (eventMonth > currentMonth ||
                  (eventMonth === currentMonth && eventDay >= currentDay))))
          ) {
            addEventToList(event, placeholder, optionsDiv1);
            counter++;
          }
        });
      }

      updateScrollbar();

      // Add "Others" and "Skip" options
      setTimeout(() => {
        const optionsDivSkip = document.getElementById(category);
        const columns = optionsDivSkip.querySelectorAll(".column_self_options");

        // Remove existing "Others" and "Skip" options if they exist
        ["Others", "Skip"].forEach((option) => {
          const removeElement = optionsDivSkip.querySelector(
            `a[href*='${option}']`
          );
          if (removeElement) {
            removeElement.remove();
          }
        });

        // Create and add "Others" option
        const othersDiv = document.createElement("div");
        othersDiv.innerHTML = `
          <a href="#" onclick="fetchmsg('I want gift suggestion for ${placeholder}','Others')" class="${styles.li_button}">Others</a>
        `;
        columns[0].appendChild(othersDiv);

        // Create and add "Skip" option
        const skipDiv = document.createElement("div");
        skipDiv.innerHTML = `
          <a href="#" onclick="fetchmsg('I want gift suggestion for ${placeholder} in all gifts','Skip')" class="${styles.li_button}">Skip</a>
        `;
        columns[1].appendChild(skipDiv);
      }, 0);
    }
  };

  xhr.send();
}

function addEventToList(event, placeholder, optionsDiv1) {
  const eventDiv = document.createElement("div");
  const escapedEventName = event.name.replace("'", "\\'");

  eventDiv.innerHTML = `
    <a
      href="#" 
      onclick="fetchmsg('I want a gift for ${placeholder} for ${escapedEventName}', '${escapedEventName}')"
      class="${styles.li_button}">
      ${event.name}
    </a>
  `;

  const selfListItem = optionsDiv1.querySelector(".li_self_options");
  const row = selfListItem.querySelector(".row_self_options");
  const columnsEvents = row.querySelectorAll(".column_self_options");
  const targetColumn = columnsEvents[0];

  targetColumn.appendChild(eventDiv);
  addedEvents.add(event.name);
  console.log("addedEvents", addedEvents);
}

function playNewMessageSound() {
  if (typeof window !== "undefined") {
    const audio = new Audio(notificationSound);
    audio.play();
  }
}
function handleNewMessage(data) {
  const { type, content, messageId, agentId } = data;
  if (type === "chat") {
    console.log("new-customer-notification");
    console.log(data);

    // Check if the messageId has already been received
    if (!receivedMessageIds.has(messageId)) {
      // Add the messageId to the set
      receivedMessageIds.add(messageId);
      var messagesContainer = document.getElementById("messages");
      // Create a new message element only if the message hasn't been received before
      var liElement = document.createElement("li");
      liElement.classList.add(styles.self);
      liElement.innerHTML = content;
      messagesContainer.appendChild(liElement);
      console.log("new-customer-notification");

      updateScrollbar();
    } else {
      console.log("Duplicate message received, ignoring...");
    }
  } else if (type == "conversation-end") {
    console.log("conversation-end");
    console.log(data);
    var messagesContainer = document.getElementById("messages");
    var liElement = document.createElement("li");
    liElement.classList.add(styles.self);
    liElement.innerHTML = `-----Conversation ended by agent-----`;
    messagesContainer.appendChild(liElement);
    console.log("conversation-end");
    // CHANGE FLAG TO FALSE
    localStorage.setItem(
      "connectToAgentFlag",
      JSON.stringify({ value: false })
    );

    socket.off("new-message");
    updateScrollbar();
  }
  saveMessageToLocal("bot", content);
  saveMessageToDatabase("liveagent", content, agentId);
}

function getConnectionData() {
  const connectionData = JSON.parse(localStorage.getItem("socketConnection"));
  const connectToAgentFlag = JSON.parse(
    localStorage.getItem("connectToAgentFlag")
  );

  return connectionData ? connectionData : null;
}

function disconnectSocketAndRestoreDialogflow() {
  // Disconnect the socket

  // Remove the "Connecting to live agent..." message
  const connectingToAgentElement = document.getElementById("connectingToAgent");
  if (connectingToAgentElement) {
    connectingToAgentElement.remove();
  }

  // Display a message to the user
  const messagesContainer = document.getElementById("messages");
  const liElement = document.createElement("li");
  liElement.classList.add(styles.self);
  liElement.innerHTML =
    "No agent available. Redirecting to virtual assistant...";
  messagesContainer.appendChild(liElement);
  updateScrollbar();
  localStorage.setItem(
    "connectToAgentFlag",
    JSON.stringify({
      value: false,
    })
  );

  // Restore the connection to Dialogflow (you need to implement this part)
  // For example, you could call a function like `restoreDialogflowConnection()`
}
function reconnectSocket() {
  const connectionData = getConnectionData();
  if (connectionData) {
    socket.auth = { username: connectionData.chatID };
    socket.connect();

    // Re-establish event listeners or perform other actions after reconnection

    const connectToAgentFlagObj = JSON.parse(
      localStorage.getItem("connectToAgentFlag")
    );

    socket.on("connect", () => {
      if (
        connectToAgentFlagObj?.value &&
        connectToAgentFlagObj?.value == true
      ) {
        socket.on("new-message", (data) => {
          handleNewMessage(data);
        });
      } else {
        socket.on("AGENT_TAKE_OVER", (data) => {
          console.log("agent has taken over");
          connectToAgentFlag();
          console.log("Received event:", data);

          var messagesContainer = document.getElementById("messages");

          var liElement = document.createElement("li");
          liElement.classList.add(styles.self);
          liElement.innerHTML = `You are now talking to ${data.agentName}. How may I assist you today?`;

          messagesContainer.appendChild(liElement);
          socket.on("new-message", (data) => {
            handleNewMessage(data);
          });

          // Handle the event data here
        });
      }
      console.log("Socket reconnected successfully");
      // ...
    });
  } else {
    console.log("No valid connection data found in localStorage");
  }
}

window.onload = function () {
  reconnectSocket();
};
