import React, { useState, KeyboardEvent, useEffect } from "react";
import styles from "./bot.module.css";
import logo from "./images/icons/Giftcart_logo.png";
import { IoMdSend } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import {
  fetchmsg,
  sendNewMessage,
  capitalizeFirstLetter,
  showOptions,
  toggleHusbandBoyfriendOptions,
  toggleWomenOptions,
  toggleOthersOptions,
  loadChatHistory,
} from "./indexNew.js";
import { VscChromeMinimize } from "react-icons/vsc";
import ChatAgent from "./chat-agent.png";

const App = () => {
  const [clicked, setClicked] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatInput, setChatInput] = useState("");
  const [showModal, setShowModal] = useState(false); // Add this line

  const handleClick = (e) => {
    e.stopPropagation();
    setClicked((prevClicked) => {
      const newClicked = !prevClicked;
      if (newClicked) {
        window.parent.postMessage("openChatBox", "*");
      } else {
        window.parent.postMessage("closeChatBox", "*");
      }
      return newClicked;
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendNewMessage();
    }
  };

  useEffect(() => {
    if (clicked) {
      loadChatHistory();
    }
  }, [clicked]);
  return (
    <section className="">
      <div
        className={`${styles.floating_chat} ${
          clicked && styles.floating_chat_expand
        }`}
      >
        {!clicked && (
          <i
            className={styles.fa_comments}
            aria-hidden="true"
            onClick={handleClick}
          />
        )}
        {clicked && (
          <div className={styles.chat}>
            <div
              className={`${styles.header} flex justify-between items-center`}
            >
              <span className={styles.iconContainer}>
                <img
                  className={styles.virtuos_heart}
                  alt="Giftcart_logo"
                  src={logo}
                />
              </span>
              <span className={styles.title}>Giftcart BOT</span>
              <div className={styles.buttonContainer}>
                <div className={styles.minimizeButton} onClick={handleClick}>
                  <VscChromeMinimize />
                </div>
                <div
                  className={styles.closeButton}
                  onClick={() => setShowModal(true)}
                >
                  <IoMdClose />
                </div>
              </div>
            </div>
            <ul className={styles.messages} id="messages">
              <li className={`${styles.self} li_self_options`}>
                With Giftcart Bot, you can access instant solutions to your
                queries without the need to wait for customer support.
              </li>

              <li className={`${styles.self} li_self_options`}>
                You can try from the following options:
                <div className={`${styles.row} row_self_options`}>
                  <div className={`${styles.column} column_self_options`}>
                    <a
                      href="#"
                      onClick={() => fetchmsg("Track Order", "Track Order")}
                      className={`${styles.li_button} birthday-link`}
                    >
                      Track Order
                    </a>
                  </div>
                  <div className={`${styles.column} column_self_options`}>
                    <a
                      href="#"
                      onClick={() => showOptions()}
                      className={`${styles.li_button} birthday-link`}
                    >
                      Send a Gift
                    </a>
                  </div>
                  <div className={`${styles.column} column_self_options`}></div>
                </div>
              </li>
              <div id="options" style={{ display: "none" }}>
                <li className={`${styles.self} li_self_options`}>
                  Select Recipient
                  <div className={`${styles.row} row_self_options`}>
                    <div className={`${styles.column} column_self_options`}>
                      <a
                        href="#"
                        onClick={(event) =>
                          toggleHusbandBoyfriendOptions(event)
                        }
                        className={`${styles.li_button} birthday-link`}
                        data-value="husband"
                      >
                        Husband
                      </a>
                      <a
                        href="#"
                        onClick={(event) =>
                          toggleHusbandBoyfriendOptions(event)
                        }
                        className={`${styles.li_button} birthday-link`}
                        data-value="boyfriend"
                      >
                        Boyfriend
                      </a>
                      <a
                        href="#"
                        onClick={(event) =>
                          toggleHusbandBoyfriendOptions(event)
                        }
                        className={`${styles.li_button} birthday-link`}
                        data-value="dad"
                      >
                        Dad
                      </a>
                      <a
                        href="#"
                        onClick={(event) =>
                          toggleHusbandBoyfriendOptions(event)
                        }
                        className={`${styles.li_button} birthday-link`}
                        data-value="brother"
                      >
                        Brother
                      </a>
                      <a
                        href="#"
                        onClick={(event) =>
                          toggleHusbandBoyfriendOptions(event)
                        }
                        className={`${styles.li_button} birthday-link`}
                        data-value="couples"
                      >
                        Couples
                      </a>
                      <a
                        href="#"
                        onClick={(event) => toggleOthersOptions(event)}
                        className={`${styles.li_button} birthday-link`}
                        data-value="kids"
                      >
                        Kids
                      </a>
                      <a
                        href="#"
                        onClick={(event) =>
                          fetchmsg("I want gift suggestion", "Others")
                        }
                        className={`${styles.li_button} birthday-link`}
                        data-value="other"
                      >
                        Other
                      </a>
                    </div>
                    <div className={`${styles.column} column_self_options`}>
                      <a
                        href="#"
                        onClick={(event) => toggleWomenOptions(event)}
                        className={`${styles.li_button} birthday-link`}
                        data-value="wife"
                      >
                        Wife
                      </a>
                      <a
                        href="#"
                        onClick={(event) => toggleWomenOptions(event)}
                        className={`${styles.li_button} birthday-link`}
                        data-value="girlfriend"
                      >
                        Girfriend
                      </a>
                      <a
                        href="#"
                        onClick={(event) => toggleWomenOptions(event)}
                        className={`${styles.li_button} birthday-link`}
                        data-value="mother"
                      >
                        Mother
                      </a>
                      <a
                        href="#"
                        onClick={(event) => toggleWomenOptions(event)}
                        className={`${styles.li_button} birthday-link`}
                        data-value="sister"
                      >
                        Sister
                      </a>
                      <a
                        href="#"
                        onClick={(event) =>
                          toggleHusbandBoyfriendOptions(event)
                        }
                        className={`${styles.li_button} birthday-link`}
                        data-value="friend"
                      >
                        Friend
                      </a>
                      <a
                        href="#"
                        onClick={(event) => toggleOthersOptions(event)}
                        className={`${styles.li_button} birthday-link`}
                        data-value="babies"
                      >
                        Babies
                      </a>
                      <a
                        href="#"
                        onClick={(event) =>
                          toggleHusbandBoyfriendOptions(event)
                        }
                        className={`${styles.li_button} birthday-link`}
                        data-value="general"
                      >
                        Skip
                      </a>
                    </div>
                  </div>
                </li>
              </div>
              {/* issue starts rahul*/}
              <div id="-options-event-placeholder" style={{ display: "none" }}>
                <li className={`${styles.self} li_self_options`}>
                  Select Occasion / Category
                  <div className={`${styles.row} row_self_options`}>
                    <div
                      className={`${styles.column} column_self_options`}
                      id="gift-recommendations-column-2"
                    ></div>
                    <div className={`${styles.column} column_self_options`}>
                      <a
                        href="javascript:fetchmsg(
        'I want gift suggestion for (placeholder) for birthday',
        'Birthday Gifts for  ' + capitalizeFirstLetter('(placeholder)')
    )"
                        className={`${styles.li_button} birthday-link`}
                      >
                        Birthday
                      </a>
                      <a
                        href="javascript:fetchmsg(
        'I want gift suggestion for (placeholder) for personalised category',
        'Personalised Gifts for  ' + capitalizeFirstLetter('(placeholder)')
    )"
                        className={`${styles.li_button} personalised-link`}
                      >
                        Personalised
                      </a>
                      <a
                        href="javascript:fetchmsg(
        'I want gift suggestion for (placeholder) for love romance',
        'Love & Romance Gifts for ' + capitalizeFirstLetter('(placeholder)')
    )"
                        className={`${styles.li_button} loveandromance-link`}
                      >
                        Love &amp; Romance
                      </a>
                      {/* <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) for love romance",
                            "Love & Romance Gifts for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} loveandromance-link`}
                      >
                        Love &amp; Romance
                      </a> */}
                      {/* <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) for wedding",
                            "Wedding Gifts for " +
                            capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} wedding-link`}
                      >
                        Wedding
                      </a> */}

                      <a
                        href="javascript:fetchmsg(
        'I want gift suggestion for (placeholder) for wedding',
        'Wedding Gifts for ' + capitalizeFirstLetter('(placeholder)')
    )"
                        className={`${styles.li_button} wedding-link`}
                      >
                        Wedding
                      </a>
                      {/* <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in naughty gifts",
                            "Naughty Gifts for " +
                            capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} naughty-link`}
                      >
                        Naughty
                      </a> */}
                      <a
                        href="javascript:fetchmsg(
        'I want gift suggestion for (placeholder) in naughty gifts',
        'Naughty Gifts for ' + capitalizeFirstLetter('(placeholder)')
    )"
                        className={`${styles.li_button} naughty-link`}
                      >
                        Naughty
                      </a>
                      {/* <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) for anniversary",
                            "Anniversary Gifts for " +
                            capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} anniversary-link`}
                      >
                        Anniversary
                      </a> */}
                      <a
                        href="javascript:fetchmsg(
        'I want gift suggestion for (placeholder) for anniversary',
        'Anniversary Gifts for ' + capitalizeFirstLetter('(placeholder)')
    )"
                        className={`${styles.li_button} anniversary-link`}
                      >
                        Anniversary
                      </a>
                      {/* <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in hatke category",
                            "Hatke Gifts for " +
                            capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} hatke-link`}
                      >
                        Hatke
                      </a> */}
                      <a
                        href="javascript:fetchmsg(
        'I want gift suggestion for (placeholder) in hatke category',
        'Hatke Gifts for ' + capitalizeFirstLetter('(placeholder)')
    )"
                        className={`${styles.li_button} hatke-link`}
                      >
                        Hatke
                      </a>
                      {/* <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) for love gifts",
                            "Love Gifts for " +
                            capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} lovegifts-link`}
                        style={{ display: "none" }}
                      >
                        Love
                      </a> */}
                      <a
                        href="javascript:fetchmsg(
        'I want gift suggestion for (placeholder) for love gifts',
        'Love Gifts for  ' + capitalizeFirstLetter('(placeholder)')
    )"
                        className={`${styles.li_button} lovegifts-link`}
                      >
                        Love
                      </a>
                      {/* <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in all gifts",
                            "Gifts for " +
                            capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} allgifts-link`}
                      >
                        All gifts
                      </a> */}
                      <a
                        href="javascript:fetchmsg(
        'I want gift suggestion for (placeholder) in all gifts',
        ' Gifts for  ' + capitalizeFirstLetter('(placeholder)')
    )"
                        className={`${styles.li_button} allgifts-link`}
                      >
                        All gifts
                      </a>
                    </div>
                  </div>
                </li>
              </div>
              <div
                id="-options-event-women-placeholder"
                style={{ display: "none" }}
              >
                <li className={`${styles.self} li_self_options`}>
                  Select Occasion / Category
                  <div className={`${styles.row} row_self_options`}>
                    <div
                      className={`${styles.column} column_self_options`}
                    ></div>
                    <div className={`${styles.column} column_self_options`}>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) for birthday",
                            "Birthday Gifts for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} birthday-link`}
                      >
                        Birthday
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in personalised category",
                            "Personalised Gifts for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} personalised-link`}
                      >
                        Personalised
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in hatke category",
                            "Hatke Gifts for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} hatke-link`}
                      >
                        Hatke
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) for love romance",
                            "Love & Romance for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} loveandromance-link`}
                      >
                        Love &amp; Romance
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in gift baskets",
                            "Gift Baskets & Hampers for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} giftbasketshampers-link`}
                      >
                        Gift Baskets &amp; Hampers
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) for anniversary",
                            "Anniversary Gifts for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} anniversary-link`}
                      >
                        Anniversary
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in jewellery gifts",
                            "Jewelleries for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} jewellery-link`}
                      >
                        Jewellery
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) for perfumes",
                            "Perfumes for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} perfumes-link`}
                      >
                        Perfumes
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) for love gifts",
                            "Love Gifts for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} lovegifts-link`}
                      >
                        Love
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in all gifts",
                            "Gifts for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} allgifts-link`}
                      >
                        All gifts
                      </a>
                    </div>
                  </div>
                </li>
              </div>
              <div
                id="-options-event-others-placeholder"
                style={{ display: "none" }}
              >
                <li className={`${styles.self} li_self_options `}>
                  Select Occasion / Category
                  <div className={`${styles.row} row_self_options`}>
                    <div
                      className={`${styles.column} column_self_options column_important_day`}
                      id="gift-recommendations-column"
                    ></div>
                    <div className={`${styles.column} column_self_options`}>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) for birthday",
                            "Birthday Gifts for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} birthday-link`}
                      >
                        Birthday
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in soft toys category",
                            "Soft Toys for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} softtoys-link`}
                      >
                        Soft Toys
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in party decor",
                            "Party Decor for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} partydecor-link`}
                      >
                        Party Decor
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in personalised category",
                            "Personalised Gifts for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} personalised-link`}
                      >
                        Personalised
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          fetchmsg(
                            "I want gift suggestion for (placeholder) in all gifts",
                            "Gifts for " +
                              capitalizeFirstLetter("(placeholder)")
                          )
                        }
                        className={`${styles.li_button} allgifts-link`}
                      >
                        All gifts
                      </a>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
            <div className={styles.footer} id="footer">
              <input
                type="text"
                id="text-box-msg"
                placeholder="Type your message..."
                className={styles.footerInput}
                onKeyPress={handleKeyPress}
              />
              <button
                id="sendMessage"
                className={styles.sendButton}
                onClick={sendNewMessage}
              >
                <IoMdSend />
              </button>
              <div
                className="flex items-center justify-center w-full display-none"
                style={{
                  display: "none",
                }}
                id="continueChatWithGiftcartBot"
              >
                <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                  Continue Chat with Giftcart Bot
                </button>
              </div>
            </div>
            {showModal && (
              <div className={styles.modal}>
                <div className={styles["modal-content"]}>
                  <p className={styles["modal-text"]}>
                    Are you sure you want to end the chat?
                  </p>
                  <div className={styles["modal-buttons"]}>
                    <button
                      onClick={() => {
                        localStorage.clear();
                        window.location.reload();
                      }}
                    >
                      Yes
                    </button>
                    <button onClick={() => setShowModal(false)}>No</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default App;
